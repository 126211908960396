<template>
    <base-page>
        <titlu-pagina Titlu="Dashboard" :AdaugaVisible='false'></titlu-pagina>

        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong>Dashboard</strong>
            </div>            
            <el-row>
                <el-col>
                    <iframe style="border:none;padding:10px;" :src="url" height="600" width="1437" title="arcgisportal"></iframe>
                </el-col>
            </el-row>
            
        </el-card>
    </base-page>

</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import TitluPagina from '../widgets/TitluPagina';

export default {
    name: "dashboard",
    extends: BasePage,
    components: {
        'base-page'   : BasePage,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            url: "https://arcgisportal.ananp.local/portal/apps/webappviewer/index.html?id=fc930a466c1f4d86af8cab3a27b292ac"
        }
    },
    methods: {
        async get_info(){
            this.refresh_info();
        },

        async refresh_info(){
            this.select_menu_item('dashboard');
        },

    },
    mounted(){
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

.top50{
    margin-top: 20px;
}

</style>
